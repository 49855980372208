/* Add a responsive design to the page */
html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #5ea4d5;
}

.mobile-display {
  display: none
}

.desktop-display {
  display: block
}

@media (max-width:480px) {
  .desktop-display {
    display: none
  }

  .mobile-display {
    display: block
  }
}

/* Style the spinning globe video */
.globe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

/* Style the text overlay */
.text-overlay {
  position: absolute;
  font-family: Georgia;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  color: coral;
  font-size: 43px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Style the clock container */
.clock {
  position: relative;
  padding-top: 109px;
  left: -209px;
  font-family: Damascus;
  color: darkslateblue;
  font-weight: bold;
  font-size: 29px;
  text-align: center;
}

@media screen and (max-width: 440px) {
  .text-overlay {
    font-size: 39px;
    text-align: center;
  }
  .clock {
    padding-top: 97px;
    left: -193px;
    font-size: 25px;
    text-align: center;
  }
}